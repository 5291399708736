export const version = '2.10.2';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent'
};

//Update here for debug
export const configUrls = {
  apiUrl : document.getElementsByTagName('base')[0].href + 'api',
  signalRUrl : document.getElementsByTagName('base')[0].href + 'clienthub'
  //apiUrl : 'https://localhost:5011/' + 'api',
  //signalRUrl : 'http://localhost:5012/' + 'clienthub'
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  FACILITIES_READ:	'FACILITIES_READ',
  FACILITIES_WRITE:	'FACILITIES_WRITE',
  SOFTWAREVERSION_READ:	'SOFTWAREVERSION_READ',
  SOFTWAREVERSION_WRITE:	'SOFTWAREVERSION_WRITE',
  ACCESSCONTROL_READ:	'ACCESSCONTROL_READ',
  ACCESSCONTROL_NOTIFICATION_READ : 'ACCESSCONTROL_NOTIFICATION_READ',
  ACCESSCONTROL_WRITE:	'ACCESSCONTROL_WRITE',
  ACCESSCONTROL_OPENDOOR: 'ACCESSCONTROL_OPENDOOR',
  ACCESSLOGS_READ : 'ACCESSLOGS_READ',
  USERACCOUNT_READ:	'USERACCOUNT_READ',
  USERACCOUNT_WRITE:	'USERACCOUNT_WRITE',
  PROFILE_READ:	'PROFILE_READ',
  PROFILE_WRITE:	'PROFILE_WRITE'
}

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings, configUrls, USER_ROLES };
