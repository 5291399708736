import httpClient from '../../helpers/http-client';

export const controllerService = {
    getControllers,
    getControllersMenuItems,
    getControllerTypes,
    getControllerInfo,
    updateController,
    connectControllersErrors,
    getControllersErrors,
    getLogs,
    getLogsCsv
};

function getControllers() {
    return httpClient.get(`/access-controller`);
}

function updateController(newValues) {
    return httpClient.put('/access-controller', {
        id: newValues.id,
        name: newValues.name,
        location: newValues.location,
        isAllowed: newValues.isAllowed,
        requestedSoftwareVersionId: newValues.requestedSoftwareVersionId
    });
}

function getControllersMenuItems() {
    return httpClient.get(`/access-controller/menu-items`);
}

function getControllerTypes() {
    return httpClient.get(`/access-controller-type`);
}

function getControllerInfo(controllerId) {
    return httpClient.get(`/access-controller/${controllerId}`);
}

function connectControllersErrors() {
    return httpClient.head(`/access-controller/failures`);
}

function getControllersErrors(facilitiesFilters) {
    if (!!facilitiesFilters && facilitiesFilters.length > 0) {
        var filters = facilitiesFilters.map(x => 'filters=' + x);
        return httpClient.get(`/access-controller/failures?${filters.join('&')}`);
    } else {
        return httpClient.get(`/access-controller/failures`);
    }

}

function getLogs(startDate, endDate, pageNumber, itemsPerPage) {
    return httpClient.get(`/access-logs?start=${startDate.toISOString()}&end=${endDate.toISOString()}&current-page=${pageNumber}&items-per-page=${itemsPerPage}`)
}

function getLogsCsv(startDate, endDate) {
    return httpClient.get(`/access-logs/csv?start=${startDate.toISOString()}&end=${endDate.toISOString()}`)
}