import axios from 'axios';
import { configUrls, settings } from '../../config';
import { handleResponse } from '../../helpers/handle-response';
import httpClient from '../../helpers/http-client';
import { LocalStorageService } from '../localstorage/localstorage.service';

export const authenticationService = {
    login,
    logout,
    getCurrentUser,
    isAuthenticated,
    refresh,
    sendResetPassword
};

var refreshLoginTimeout;

function setRefreshLoginTimeout(duration) {
    clearTimeout(refreshLoginTimeout);
    refreshLoginTimeout = setTimeout(()=> {
        refresh();
    }, duration)
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: new URLSearchParams({
            'username': username,
            'password': password,
            'grant_type': 'password'
          }),
    };

    return fetch(`${configUrls.apiUrl}/identity`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            LocalStorageService.setToken(user);
            setRefreshLoginTimeout(user.expiryIn - 1000);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    LocalStorageService.clearToken();
    clearTimeout(refreshLoginTimeout);
}

function refresh() {
    return axios.post(`${configUrls.apiUrl}/identity/refresh-token`,
      {
        "accessToken" : LocalStorageService.getAccessToken(),
        "refreshToken": LocalStorageService.getRefreshToken()
      })
      .then(res => {
        const refreshPromise = new Promise((resolve, reject) => {
            if (res.status === 200) {
                // 1) put token to LocalStorage
                LocalStorageService.setToken(res.data);
    
                // 2) Change Authorization header
                httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + LocalStorageService.getAccessToken();
                setRefreshLoginTimeout(LocalStorageService.getExpirationToken());
    
                resolve(res.data.accessToken);
            } else {
                reject();
            }
         });
        
        return refreshPromise;
      })
}

function getCurrentUser() {
    return LocalStorageService.getUser();
}

function isAuthenticated() {
        
    var user = this.getCurrentUser();
    if (!user)
        return false;

    return true;

}

function sendResetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: new URLSearchParams({
            'email': email
          })
    };

    return fetch(`${configUrls.apiUrl}/identity/reset-password`, requestOptions)
        .then(resp => {
            if(resp.status === 200)
                return true;
            else if(resp.status === 204)
                return "Utilisateur introuvable";
            else
                return "Une erreur est survenue. Merci de réessayer.";
        })
        .catch(err=> err);
}