import { USER_ROLES, version } from './config';

export const facilitiesRoutes = {
  name: 'Installations',
  to: '/facilities',
  exact: true,
  icon: 'parking',
  role: USER_ROLES.FACILITIES_READ
};

export const softwareVersionRoutes = {
  name: 'Version logiciel',
  to: '/software-versions',
  exact: true,
  icon: 'code-branch',
  role: USER_ROLES.SOFTWAREVERSION_READ
};

export const accessControllerRoutes = {
  name: "Contrôleurs d'accès",
  to: '/controllers',
  exact: true,
  icon: 'puzzle-piece',
  role: USER_ROLES.ACCESSCONTROL_READ
};

export const accessLogsRoutes = {
  name: "Logs",
  to: '/access-logs',
  exact: true,
  icon: 'book',
  role: USER_ROLES.ACCESSLOGS_READ
};

export const notificationsRoutes = {
  name: "Notifications",
  to: '/notifications-page',
  exact: true,
  icon: 'book',
  role: USER_ROLES.ACCESSLOGS_READ
};

export const usersRoutes = {
  name: 'Utilisateurs',
  to: '/users',
  exact: true,
  icon: 'user-cog',
  children: [
    { to: '/users/accounts',
      name: 'Comptes', 
      exact: true ,
      role: USER_ROLES.USERACCOUNT_READ
    },
    {
      to: '/users/profiles',
      name: 'Profils',
      exact: true,
      role: USER_ROLES.USERACCOUNT_READ
    }
  ],
  role: USER_ROLES.USERACCOUNT_READ
};

// export const homeRoutes = {
//   name: 'Home',
//   to: '/',
//   exact: true,
//   icon: 'chart-pie',
//   children: [
//     {
//       to: '/',
//       name: 'Dashboard',
//       exact: true
//     },
//     { to: '/feed', name: 'Feed', exact: true },
//     { to: '/landing', name: 'Landing' }
//   ]
// };

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    {
      to: '/authentication/basic',
      name: 'Basic',
      children: [
        { to: '/authentication/basic/login', name: 'Login' },
        { to: '/authentication/basic/logout', name: 'Logout' },
        { to: '/authentication/basic/register', name: 'Register' },
        { to: '/authentication/basic/forget-password', name: 'Forgot password' },
        { to: '/authentication/basic/password-reset/:token/:login', name: 'Reset password' },
        { to: '/authentication/basic/password-reset', name: 'Reset password' },
        { to: '/authentication/basic/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/basic/lock-screen', name: 'Lock screen' }
      ]
    }
  ]
};

export default [
  // homeRoutes,
  facilitiesRoutes,
  softwareVersionRoutes,
  accessControllerRoutes,
  accessLogsRoutes,
  usersRoutes,
  notificationsRoutes
  // pageRoutes,
  // chatRoutes,
  // kanbanRoutes,
  // calenderRoutes,
  // emailRoutes,
  // authenticationRoutes,
  // ECommerceRoutes,
  // widgetsRoutes,
  // componentRoutes,
  // utilityRoutes,
  // pluginRoutes,
  // documentationRoutes,
  // changelogRoutes
];
