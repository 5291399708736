
export const LocalStorageService = {
    setToken,
    getAccessToken,
    getRefreshToken,
    getUser,
    getExpirationToken,
    clearToken
  };

function setToken(tokenObj) {
    localStorage.setItem('user', JSON.stringify(tokenObj));
}

function getAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!!user) {
        return user.accessToken;
    }
    return null;    
}

function getRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!!user) {
        return user.refreshToken;
    }
    return null;
}

function getExpirationToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!!user) {
        return user.expiryIn;
    }
    return null;
}

function getUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;    
}

function clearToken() {
    localStorage.removeItem('user');
}
