import React from 'react';
import { authenticationService } from '../../data/authentication/authentication.service';


const PrivateComponent = ({role, children}) => {
    const currentUser = authenticationService.getCurrentUser();
    
    if (!!currentUser) {
        if (!!role && !!currentUser.roles && (
            (currentUser.roles.indexOf(role) > -1) 
                // || (currentUser.roles.indexOf('ADMIN') > -1)
            )
            ) {
            // role authorized so return child element
            return React.Children.only(children);
        }
    }
    return '';
  };

export default PrivateComponent;