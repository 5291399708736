import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import { isIterableArray } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import { controllerService } from "../../data/controller/controller.service";
import { LocalStorageService } from '../../data/localstorage/localstorage.service';
import { configUrls } from '../../config';
import { toast } from 'react-toastify';
import { HubConnectionBuilder } from "@microsoft/signalr";

const NotificationDropdown = () => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
      let isMounted = true;
      let connection;

      const fetchData = () => {
          controllerService.connectControllersErrors();
      }

      const setUpSignalRConnection = () => {
          connection = new HubConnectionBuilder()
              .withUrl(configUrls.signalRUrl,  
              {
                  accessTokenFactory: () => LocalStorageService.getAccessToken()
              })
              .withAutomaticReconnect()
              .build();
     
          connection.on('ControllerErrorOccured', (eventMessage) => {
              setAlerts(eventMessage);
          });
      
          return connection.start();
      };

      if(isMounted){
          setUpSignalRConnection()
              .then(fetchData)
              .catch(err=> toast.error(err));
      }
  
      return () => {
          connection.stop();
          isMounted = false;
      }

  }, []);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle
        nav
        className={classNames('px-0', {
          'notification-indicator notification-indicator-warning': alerts
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
          <FalconCardHeader className="card-header" title="Notifications" titleTag="h6" light={false}>
          </FalconCardHeader>
          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">NEW</div>
            {isIterableArray(alerts) &&
              alerts.map((alert, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Alert color="danger">
                    <p key={index}>{alert}</p>
                  </Alert>
                </ListGroupItem>
              ))}
          </ListGroup>
          <div className="card-footer text-center border-top" onClick={handleToggle}>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
