import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { USER_ROLES } from '../../config';
import { authenticationService } from '../../data/authentication/authentication.service';

export const PrivateRoute = ({ component: Component, role, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.getCurrentUser();
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/authentication/basic/login', state: { from: props.location } }} />
        }
        // check if route is restricted by role
        if (!!role && !!currentUser.roles && (currentUser.roles.indexOf(role) === -1) 
            // && (currentUser.roles.indexOf(USER_ROLES.ADMIN) === -1)
        ) {
            // role not authorised so redirect to unauthorize page
            return <Redirect to={{ pathname: '/unauthorize'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)