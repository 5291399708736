import axios from 'axios';
import { toast } from 'react-toastify';
import { configUrls } from '../config'
import { authenticationService } from '../data/authentication/authentication.service'
import { LocalStorageService } from '../data/localstorage/localstorage.service'
import { history } from './history';

const instance = axios.create({
  baseURL: configUrls.apiUrl
});

instance.interceptors.request.use(
  config => {
      const token = LocalStorageService.getAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
      return config;
  },
  error => {
      Promise.reject(error)
  });

  let isAlreadyFetchingAccessToken = false
  let subscribers = []
  
  function onAccessTokenFetched(access_token) {
    subscribers = subscribers.map(callback => callback(access_token))
  }
  
  function addSubscriber(callback) {
    subscribers.push(callback)
  }
  
  instance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      const { config, response: { status } } = error
      const originalRequest = config
  
      if (status === 401) {
   
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            resolve(axios(originalRequest).then(res=>res.data))
          })
        })

        if (!isAlreadyFetchingAccessToken) {

          isAlreadyFetchingAccessToken = true

          authenticationService.refresh()
            .then(
              (access_token) => {
                isAlreadyFetchingAccessToken = false
                onAccessTokenFetched(access_token)
              },
              err=>{
                authenticationService.logout();
                history.push('/authentication/basic/login');
              });
        }

        return retryOriginalRequest
      }
      
    const resMessage =
              (error.response &&
                  error.response.data &&
                  error.response.data.Message) ||
              error.message ||
              error.toString();
      
    toast.error(resMessage);

    return Promise.reject(error)
  })

export default instance;