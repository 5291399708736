import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownComponents from './NavbarDropdownComponents';
import {
  authenticationRoutes,
  facilitiesRoutes,
  softwareVersionRoutes,
  accessControllerRoutes,
  accessLogsRoutes,
  usersRoutes,
  notificationsRoutes
} from '../../routes';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints, getPageName } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint, USER_ROLES } from '../../config';
import AppContext from '../../context/Context';
import PrivateComponent from '../routes/PrivateComponent';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const components = [usersRoutes];

  // const pages = [pageRoutes, calenderRoutes, kanbanRoutes, widgetsRoutes, chatRoutes, emailRoutes, ECommerceRoutes];

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  const isLanding = getPageName('landing');
  return (
    <>
      {/* <NavbarDropdown
        title={homeRoutes.name}
        items={homeRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      /> 
      <NavbarDropdown title={pageRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdownComponents
        title={componentRoutes.name}
        items={components}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={authenticationRoutes.name}
        items={authenticationRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      */}
      {isTopNav && !isLanding && (
        <PrivateComponent role={facilitiesRoutes.role}>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to={facilitiesRoutes.to}>
              Installations
            </NavLink>
          </NavItem>
        </PrivateComponent>
      )}
      {isTopNav && !isLanding && (
        <PrivateComponent role={softwareVersionRoutes.role}>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to={softwareVersionRoutes.to}>
              Version logiciel
            </NavLink>
          </NavItem>
        </PrivateComponent>
      )}
      {isTopNav && !isLanding && (
        <PrivateComponent role={accessControllerRoutes.role}>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to={accessControllerRoutes.to}>
              Contrôleur d'accès
            </NavLink>
          </NavItem>
        </PrivateComponent>
      )}
      {isTopNav && !isLanding && (
        <PrivateComponent role={accessLogsRoutes.role}>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to={accessLogsRoutes.to}>
              Logs
            </NavLink>
          </NavItem>
        </PrivateComponent>
      )}
      {isTopNav && !isLanding && (
        <PrivateComponent role={notificationsRoutes.role}>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to={notificationsRoutes.to}>
              Logs
            </NavLink>
          </NavItem>
        </PrivateComponent>
      )}
      {isTopNav && !isLanding && (
        <PrivateComponent role={usersRoutes.role}>
          <NavbarDropdown
            title={usersRoutes.name}
            items={usersRoutes.children}
            handleSetNavbarCollapsed={handleSetNavbarCollapsed}
          />
        </PrivateComponent>
      )}

      {isLanding && (
        <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/changelog">
            Changelog
          </NavLink>
        </NavItem>
      )}
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
